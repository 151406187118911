'use client'

import { useCallback, useEffect, useMemo, useState } from 'react'
import { useIntl } from 'react-intl'
import { useDispatch } from 'react-redux'
import uuid from 'uuid'
import { Button, Spacer, Dialog, Cell, Navigation, Image, Text, Divider } from '@vinted/web-ui'
import { X24 } from '@vinted/monochrome-icons'

import { getTaxpayersSpecialVerificationDeadline } from 'data/api'
import * as faqEntryActions from 'state/faq-entry/actions'
import useAsset from 'hooks/useAsset'
import useTranslate from 'hooks/useTranslate'
import useRefUrl from 'hooks/useRefUrl'
import FaqEntryUrl from 'components/FaqEntryUrl'
import ContentLoader from 'components/ContentLoader'
import ErrorState from 'components/ErrorState'
import { navigateToPage } from 'libs/utils/window'
import { SPECIAL_VERIFICATION_FORM_URL_WITH_REF } from 'constants/routes'
import { UiState } from 'constants/ui'
import { AccessChannel } from 'constants/index'
import { MS_PER_SECOND } from 'constants/date'
import { FaqEntryType } from 'constants/faq-entry'
import useTracking from 'hooks/useTracking'
import { clickEvent, viewScreenEvent } from 'libs/common/event-tracker/events'
import { Screen } from 'constants/tracking/screens'
import { ClickableElement } from 'constants/tracking/clickable-elements'

type Props = {
  show: boolean
  onClose: () => void
}

const TaxpayersSpecialVerificationFailureModal = ({ show, onClose }: Props) => {
  const translate = useTranslate('taxpayer_special_verification.failure_modal')
  const { track } = useTracking()
  const { formatDate } = useIntl()
  const dispatch = useDispatch()
  const refUrl = useRefUrl()
  const asset = useAsset('/assets/taxpayers-special-verification')

  const specialVerificationSessionId = useMemo(() => uuid.v4(), [])

  useEffect(() => {
    // The FAQ entry is only fetched when component mounts, causing the Contact Us button to appear after the modal is already open
    dispatch(
      faqEntryActions.fetchFaqEntryByTypeRequest({ type: FaqEntryType.Dac7SpecialVerification }),
    )
  }, [dispatch])

  const [uiState, setUiState] = useState(UiState.Idle)
  const [deadlineDate, setDeadlineDate] = useState<string | null>(null)

  const formatDeadlineUnix = useCallback(
    (unixTime: number) => {
      const formattedDeadlineDate = formatDate(unixTime * MS_PER_SECOND, {
        year: 'numeric',
        month: 'long',
        day: 'numeric',
      })

      return formattedDeadlineDate
    },
    [formatDate],
  )

  useEffect(() => {
    const fetchSpecialVerificationDeadline = async () => {
      setUiState(UiState.Pending)
      const response = await getTaxpayersSpecialVerificationDeadline()

      if ('errors' in response) {
        setUiState(UiState.Failure)

        return
      }

      setUiState(UiState.Success)
      setDeadlineDate(formatDeadlineUnix(response.deadline))
    }

    fetchSpecialVerificationDeadline()
  }, [formatDeadlineUnix])

  useEffect(() => {
    const trackScreenView = () => {
      track(viewScreenEvent({ screen: Screen.TaxpayersSpecialVerificationFailure }))
    }

    if (!show) return

    trackScreenView()
  }, [show, track])

  const handleModalConfirm = () => {
    track(
      clickEvent({
        screen: Screen.TaxpayersSpecialVerificationFailure,
        target: ClickableElement.FixTaxpayersReport,
        targetDetails: JSON.stringify({
          verification_id: specialVerificationSessionId,
        }),
      }),
    )

    navigateToPage(SPECIAL_VERIFICATION_FORM_URL_WITH_REF(refUrl, specialVerificationSessionId))
  }

  const handleSecondaryButtonClick = () => {
    track(
      clickEvent({
        screen: Screen.TaxpayersSpecialVerificationFailure,
        target: ClickableElement.ContactUs,
      }),
    )
  }

  const renderHeader = () => {
    return (
      <>
        <Navigation
          body={<Text type={Text.Type.Title} text={translate('header.title')} />}
          right={
            <Button
              testId="special-verification-failure-close-button"
              styling={Button.Styling.Flat}
              onClick={onClose}
              iconName={X24}
              inline
            />
          }
        />
        <Divider />
      </>
    )
  }

  const renderBody = () => {
    return (
      <>
        <Image src={asset('/document-pen.svg')} alt="" />
        <Spacer size={Spacer.Size.XLarge} />
        <Text alignment={Text.Alignment.Left} type={Text.Type.Heading} text={translate('title')} />
        <Spacer size={Spacer.Size.Large} />
        <div className="unordered-list-margin-top-none">
          <Text
            html
            alignment={Text.Alignment.Left}
            type={Text.Type.Body}
            text={translate('body_1')}
          />
        </div>
        <Spacer size={Spacer.Size.Large} />
        <Text
          alignment={Text.Alignment.Left}
          type={Text.Type.Body}
          text={translate('body_2', { deadline: <Text bold>{deadlineDate}</Text> })}
        />
        <Text alignment={Text.Alignment.Center} type={Text.Type.Body} />
      </>
    )
  }

  const renderFooter = () => {
    return (
      <>
        <Spacer size={Spacer.Size.Large} />
        <Button
          styling={Button.Styling.Filled}
          text={translate('actions.primary')}
          testId="special-verification-failure-primary-button"
          onClick={handleModalConfirm}
        />
        <Spacer size={Spacer.Size.Large} />
        <FaqEntryUrl
          type={FaqEntryType.Dac7SpecialVerification}
          accessChannel={AccessChannel.ProductLink}
          render={url => (
            <a
              data-testid="dac7-special-verification-faq-entry-link"
              className="u-disable-underline"
              href={url}
              target="_blank"
              rel="noopener noreferrer"
            >
              <Button
                styling={Button.Styling.Outlined}
                text={translate('actions.secondary')}
                testId="special-verification-failure-secondary-button"
                onClick={handleSecondaryButtonClick}
              />
            </a>
          )}
        />
      </>
    )
  }

  const renderContent = () => {
    if (uiState === UiState.Failure) return <ErrorState />

    if (uiState === UiState.Pending)
      return <ContentLoader testId="content-loader" size={ContentLoader.Size.Large} />

    return (
      <Cell>
        {renderBody()}
        {renderFooter()}
      </Cell>
    )
  }

  return (
    <Dialog show={show} className="u-text-center">
      {renderHeader()}
      {renderContent()}
    </Dialog>
  )
}

export default TaxpayersSpecialVerificationFailureModal
