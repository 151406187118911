'use client'

import { Button, Cell, Dialog, Spacer, Text } from '@vinted/web-ui'

import { TAXPAYER_FORM_URL_WITH_REF } from 'constants/routes'
import useRefUrl from 'hooks/useRefUrl'
import useTranslate from 'hooks/useTranslate'
import { navigateToPage } from 'libs/utils/window'

type Props = {
  show: boolean
  onClose: () => void
}

const TaxpayerFillFormModal = ({ show, onClose }: Props) => {
  const translate = useTranslate('taxpayer_fill_form_modal')
  const refUrl = useRefUrl()

  const handleFillForm = () => navigateToPage(TAXPAYER_FORM_URL_WITH_REF(refUrl))

  const handleClose = () => onClose()

  return (
    <Dialog show={show}>
      <Cell>
        <div className="u-flexbox u-flex-direction-column u-align-items-center">
          <Text type={Text.Type.Heading} text={translate('title')} />
          <Spacer />
          <Text text={translate('body')} />
          <Spacer size={Spacer.Size.X2Large} />
          <Button
            text={translate('actions.primary')}
            onClick={handleFillForm}
            styling={Button.Styling.Filled}
          />
          <Spacer />
          <Button
            text={translate('actions.secondary')}
            styling={Button.Styling.Flat}
            onClick={handleClose}
          />
        </div>
      </Cell>
    </Dialog>
  )
}

export default TaxpayerFillFormModal
