'use client'

import { MouseEvent, useEffect, useMemo, useState } from 'react'
import { InfoBanner } from '@vinted/web-ui'
import { useSelector } from 'react-redux'
import uuid from 'uuid'

import {
  TaxpayerBannerRenderLocation,
  TaxpayerBannerTrackingId,
  TaxpayerBannerType,
  TaxpayerSpecialVerificationLinks,
} from 'constants/taxpayer'
import {
  TaxpayerBannerActionModel,
  TaxpayerBannerModel,
  TaxpayerEducationModel,
} from 'types/models'
import {
  dismissTaxpayerFillFormModal,
  dismissTaxpayerRestrictionBanner,
  dismissTaxpayerRestrictionModal,
  getTaxpayersSpecialVerificationEducation,
} from 'data/api'
import { navigateToPage } from 'libs/utils/window'
import useTracking from 'hooks/useTracking'
import { clickEvent, viewEvent } from 'libs/common/event-tracker/events'
import { ViewableElement } from 'constants/tracking/viewable-elements'
import { getScreenName } from 'state/screen/selectors'
import { getIsFeatureSwitchEnabled } from 'state/feature-switches/selectors'
import { ClickableElement } from 'constants/tracking/clickable-elements'
import { Screen } from 'constants/tracking/screens'
import TaxpayerRestrictionInfoModal from 'components/Taxpayer/TaxpayerRestrictionInfoModal'
import useRefUrl from 'hooks/useRefUrl'
import TaxpayerEducationModal from 'pages/TaxpayerForm/components/TaxpayerEducationModal'
import { UiState } from 'constants/ui'
import { transformTaxpayerEducation } from 'data/transformers/taxpayer'
import TaxpayersSpecialVerificationFailureModal from 'components/TaxpayersSpecialVerificationFailureModal'
import TaxpayersSpecialVerificationRestrictionInfoModal from 'components/Taxpayer/TaxpayersSpecialVerificationRestrictionInfoModal'
import { SPECIAL_VERIFICATION_FORM_URL_WITH_REF } from 'constants/routes'
import useAbTest from 'hooks/useAbTest'
import { AbTestVariant } from 'constants/abtest'
import TaxpayerFillFormModal from 'components/Taxpayer/TaxpayerFillFormModal'
import { getSessionStorageItem, setSessionStorageItem } from 'libs/utils/sessionStorage'

type Props = {
  banner: TaxpayerBannerModel
  isBannerInFeed: boolean
  renderLocation: TaxpayerBannerRenderLocation
  screen?: Screen
}

type InfoBannerActionProps = {
  text: string
  callback: (event: MouseEvent<HTMLAnchorElement | HTMLButtonElement>) => void
}

type InfoBannerActionReturnProps =
  | []
  | [InfoBannerActionProps]
  | [InfoBannerActionProps, InfoBannerActionProps]

const WARDROBE_FILL_FORM_MODAL_KEY = 'is_taxpayer_fill_form_wardrobe_modal_seen'

const TaxpayerBanner = ({ banner, isBannerInFeed, screen, renderLocation }: Props) => {
  const { track } = useTracking()
  const refUrl = useRefUrl()

  const [show, setShow] = useState(true)
  const [showTaxpayersRestrictionModal, setShowTaxpayersRestrictionModal] = useState(true)
  const [
    showTaxpayersSpecialVerificationRestrictionModal,
    setShowTaxpayersSpecialVerificationRestrictionModal,
  ] = useState(true)

  const [taxpayersEducation, setTaxpayersEducation] = useState<TaxpayerEducationModel | null>(null)
  const [showTaxpayersEducationModal, setShowTaxpayersEducationModal] = useState(false)
  const [taxpayerEducationModalUiState, setTaxpayerEducationModalUiState] = useState(UiState.Idle)

  const [
    showTaxpayersSpecialVerificationFailureModal,
    setShowTaxpayersSpecialVerificationFailureModal,
  ] = useState(false)

  const screenName = useSelector(getScreenName)
  const isTaxpayerSpecialVerificationBannerFsEnabled = useSelector(
    getIsFeatureSwitchEnabled('web_special_verification_taxpayers_banners'),
  )
  const specialVerificationSessionId = useMemo(() => uuid.v4(), [])

  const [showFillFormModal, setShowFillFormModal] = useState(false)
  const shortFunnelAbTest = useAbTest({
    abTestName: 'taxpayers_dac7_flow_aug_2024',
  })
  const isShortFunnelAbTestEnabled = shortFunnelAbTest?.variant === AbTestVariant.C
  const isForcedFormEnabled = useSelector(
    getIsFeatureSwitchEnabled('web_taxpayers_banners_force_redirect'),
  )

  useEffect(() => {
    if (!isForcedFormEnabled) return
    if (renderLocation !== TaxpayerBannerRenderLocation.Wardrobe) return
    if (!isShortFunnelAbTestEnabled) return
    if (getSessionStorageItem(WARDROBE_FILL_FORM_MODAL_KEY) === 'true') return
    if (banner.id !== TaxpayerBannerTrackingId.DataRequest) return

    setShowFillFormModal(true)
    setSessionStorageItem(WARDROBE_FILL_FORM_MODAL_KEY, 'true')
  }, [banner.id, isForcedFormEnabled, isShortFunnelAbTestEnabled, renderLocation])

  useEffect(() => {
    if (!isForcedFormEnabled) return
    if (renderLocation !== TaxpayerBannerRenderLocation.Feed) return
    if (!banner.newsFeed.forceRedirect) return
    if (!isShortFunnelAbTestEnabled) return
    if (banner.id !== TaxpayerBannerTrackingId.DataRequest) return

    setShowFillFormModal(true)
    dismissTaxpayerFillFormModal()
  }, [
    banner.id,
    banner.newsFeed.forceRedirect,
    isForcedFormEnabled,
    isShortFunnelAbTestEnabled,
    renderLocation,
  ])

  useEffect(() => {
    if (!banner.id) return
    if (isBannerInFeed && banner.newsFeed.dismissed) return

    track(
      viewEvent({
        target: banner.isSpecialVerification
          ? ViewableElement.TaxpayersDac7SpecialVerificationBanner
          : ViewableElement.TaxpayersDac7Banner,
        targetDetails: banner.id,
        screen: screen || screenName,
      }),
    )
  }, [
    banner.id,
    banner.isSpecialVerification,
    banner.newsFeed.dismissed,
    isBannerInFeed,
    isShortFunnelAbTestEnabled,
    screen,
    screenName,
    track,
  ])

  useEffect(() => {
    const fetchTaxpayersEducationSections = async () => {
      setTaxpayerEducationModalUiState(UiState.Pending)

      const response = await getTaxpayersSpecialVerificationEducation()

      if ('errors' in response) {
        setTaxpayerEducationModalUiState(UiState.Failure)

        return
      }

      const education = transformTaxpayerEducation(response.education)

      setTaxpayerEducationModalUiState(UiState.Success)
      setTaxpayersEducation(education)
    }

    if (!banner.isSpecialVerification) return

    fetchTaxpayersEducationSections()
  }, [banner.isSpecialVerification])

  const bannerTypeMapping = {
    [TaxpayerBannerType.Warning]: InfoBanner.Type.Warning,
    [TaxpayerBannerType.Error]: InfoBanner.Type.Error,
    [TaxpayerBannerType.Success]: InfoBanner.Type.Success,
    [TaxpayerBannerType.Info]: InfoBanner.Type.Info,
  }
  const maximumBannerActions = 2

  const getBannerType = () => {
    return bannerTypeMapping[banner.type]
  }

  const handleBannerActionClick = (actionLink: string) => {
    navigateToPage(`${actionLink}?ref_url=${refUrl}`)
  }

  const handleSpecialVerificationBannerActionClick = (actionLink: string) => {
    switch (actionLink) {
      case TaxpayerSpecialVerificationLinks.StartVerification:
        setShowTaxpayersEducationModal(true)
        break

      case TaxpayerSpecialVerificationLinks.CorrectYourReport:
        setShowTaxpayersSpecialVerificationFailureModal(true)
        break

      case TaxpayerSpecialVerificationLinks.NavigateToSpecialVerificationForm:
        navigateToPage(SPECIAL_VERIFICATION_FORM_URL_WITH_REF(refUrl, specialVerificationSessionId))
        break

      default:
        break
    }
  }

  const transformBannerAction = (action: TaxpayerBannerActionModel) => ({
    text: action.title,
    callback: () => {
      track(
        clickEvent({
          screen: screenName,
          target: banner.isSpecialVerification
            ? ClickableElement.TaxpayersDac7SpecialVerificationBanner
            : ClickableElement.TaxpayersDac7Banner,
          targetDetails: JSON.stringify({
            button: action.type === 'primary' ? 'finalise' : 'learn',
            id: banner.id,
          }),
        }),
      )

      if (banner.isSpecialVerification) {
        handleSpecialVerificationBannerActionClick(action.link)

        return
      }

      handleBannerActionClick(action.link)
    },
  })

  const getBannerActions = (): InfoBannerActionReturnProps => {
    if (!banner.actions) return []

    const primaryAction = banner.actions.find(action => action.type === 'primary')
    const secondaryAction = banner.actions.find(action => action.type === 'secondary')

    if (banner.actions.length === maximumBannerActions && primaryAction && secondaryAction) {
      return [transformBannerAction(primaryAction), transformBannerAction(secondaryAction)]
    }

    if (banner.actions.length === 1 && primaryAction) {
      return [transformBannerAction(primaryAction)]
    }

    if (banner.actions.length === 1 && secondaryAction) {
      return [transformBannerAction(secondaryAction)]
    }

    return []
  }

  const handleBannerDismiss = () => {
    if (
      banner.id !== TaxpayerBannerTrackingId.VerificationSuccess &&
      !(banner.newsFeed.isDismissible && isBannerInFeed)
    )
      return

    setShow(false)
    dismissTaxpayerRestrictionBanner()

    track(
      clickEvent({
        screen: screenName,
        target: banner.isSpecialVerification
          ? ClickableElement.TaxpayersDac7SpecialVerificationBanner
          : ClickableElement.TaxpayersDac7Banner,
        targetDetails: JSON.stringify({
          button: 'close',
          id: banner.id,
        }),
      }),
    )
  }

  const handleTaxpayersRestrictionModalClose = () => {
    setShowTaxpayersRestrictionModal(false)
    dismissTaxpayerRestrictionModal()
  }

  const handleTaxpayersSpecialVerificationRestrictionModalClose = () => {
    setShowTaxpayersSpecialVerificationRestrictionModal(false)
    dismissTaxpayerRestrictionModal()
  }

  const handleTaxpayersEducationModalClose = () => {
    setShowTaxpayersEducationModal(false)
  }

  const handleTaxpayersSpecialVerificationFailureModalClose = () => {
    setShowTaxpayersSpecialVerificationFailureModal(false)
  }

  const handleTaxpayersEducationModalConfirm = () => {
    track(
      clickEvent({
        screen: Screen.TaxpayersSpecialVerificationEducation,
        target: ClickableElement.StartSpecialVerification,
        targetDetails: JSON.stringify({ verification_id: specialVerificationSessionId }),
      }),
    )

    navigateToPage(SPECIAL_VERIFICATION_FORM_URL_WITH_REF(refUrl, specialVerificationSessionId))
  }

  const renderTaxpayersRestrictionModal = () => {
    if (!isBannerInFeed) return null
    if (!banner.newsFeed.showModal) return null
    if (banner.isSpecialVerification) return null

    return (
      <TaxpayerRestrictionInfoModal
        show={showTaxpayersRestrictionModal}
        isSellingBlocked={banner.id === TaxpayerBannerTrackingId.SellingBlocked}
        screen={screen}
        onClose={handleTaxpayersRestrictionModalClose}
      />
    )
  }

  const renderTaxpayersSpecialVerificationRestrictionModal = () => {
    if (!isBannerInFeed) return null
    if (!banner.newsFeed.showModal) return null
    if (!banner.isSpecialVerification) return null

    if (!isTaxpayerSpecialVerificationBannerFsEnabled) return null

    return (
      <TaxpayersSpecialVerificationRestrictionInfoModal
        show={showTaxpayersSpecialVerificationRestrictionModal}
        screen={screen}
        onClose={handleTaxpayersSpecialVerificationRestrictionModalClose}
      />
    )
  }

  const renderTaxpayersEducationalModal = () => {
    if (!banner.isSpecialVerification) return null

    return (
      <TaxpayerEducationModal
        show={showTaxpayersEducationModal}
        taxpayerEducation={taxpayersEducation}
        isSpecialVerification={banner.isSpecialVerification}
        uiState={taxpayerEducationModalUiState}
        onClose={handleTaxpayersEducationModalClose}
        onConfirm={handleTaxpayersEducationModalConfirm}
      />
    )
  }

  const renderSpecialVerificationFailureModal = () => {
    if (!banner.isSpecialVerification) return null

    return (
      <TaxpayersSpecialVerificationFailureModal
        show={showTaxpayersSpecialVerificationFailureModal}
        onClose={handleTaxpayersSpecialVerificationFailureModalClose}
      />
    )
  }

  const handleFillFormModalClose = () => {
    setShowFillFormModal(false)
  }

  const renderFillFormModal = () => (
    <TaxpayerFillFormModal show={showFillFormModal} onClose={handleFillFormModalClose} />
  )

  if (banner.newsFeed.dismissed && banner.id === TaxpayerBannerTrackingId.VerificationSuccess)
    return null
  if (isBannerInFeed && banner.newsFeed.dismissed) return null
  if (!show) return null

  const isBannnerClosable =
    (banner.newsFeed.isDismissible && isBannerInFeed) ||
    banner.id === TaxpayerBannerTrackingId.VerificationSuccess

  if (!banner.showInScreens.includes(renderLocation)) return null

  return (
    <>
      <InfoBanner
        title={banner.title}
        body={banner.body}
        closable={isBannnerClosable}
        styling={InfoBanner.Styling.Tight}
        type={getBannerType()}
        actions={getBannerActions()}
        onClose={handleBannerDismiss}
        testId="taxpayer-banner"
      />
      {renderTaxpayersRestrictionModal()}
      {renderTaxpayersSpecialVerificationRestrictionModal()}
      {renderTaxpayersEducationalModal()}
      {renderSpecialVerificationFailureModal()}
      {renderFillFormModal()}
    </>
  )
}

export default TaxpayerBanner
