export enum TaxpayerBannerType {
  Info = 'info',
  Success = 'success',
  Warning = 'warning',
  Error = 'error',
}

export enum TaxpayerBannerTrackingId {
  FirstReminder = 'first_reminder',
  SecondReminder = 'second_reminder',
  NoFillReminder = 'no_fill_reminder',
  DataRequest = 'data_request',
  SellingBlocked = 'selling_blocked',
  Restricted = 'restricted',
  VerificationInitiation = 'verification_initiation',
  VerificationProgress = 'verification_progress',
  VerificationWarning = 'verification_warning',
  VerificationCritical = 'verification_critical',
  VerificationCorrectionInitation = 'verification_correction_initiation',
  VerificationCorrectionProgress = 'verification_correction_progress',
  VerificationCorrectionWarning = 'verification_correction_warning',
  VerificationCorrectionCritical = 'verification_correction_critical',
  VerificationUnexpectedErrorInitiation = 'verification_unexpected_error_initiation',
  VerificationUnexpectedErrorProgress = 'verification_unexpected_error_progress',
  VerificationUnexpectedErrorWarning = 'verification_unexpected_error_warning',
  VerificationUnexpectedErrorCritical = 'verification_unexpected_error_critical',
  VerificationSuccess = 'verification_success',
}

export enum TaxpayerSpecialVerificationLinks {
  StartVerification = '/settings/taxpayer_verification_education',
  CorrectYourReport = '/settings/taxpayer_verification_correction',
  NavigateToSpecialVerificationForm = '/settings/taxpayer_verification_form',
}

export enum TaxpayerBannerRenderLocation {
  Feed = 'feed',
  Wallet = 'wallet',
  Wardrobe = 'wardrobe',
  TaxpayerSummary = 'taxpayer_summary',
}
