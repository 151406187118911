'use client'

import { useEffect } from 'react'
import { Button, Cell, Divider, Dialog, Navigation, Spacer, Text } from '@vinted/web-ui'
import { X16 } from '@vinted/monochrome-icons'

import useTranslate from 'hooks/useTranslate'
import { navigateToPage } from 'libs/utils/window'
import { TAXPAYER_EDUCATION_URL_WITH_REF, TAXPAYER_FORM_URL_WITH_REF } from 'constants/routes'
import { dismissTaxpayerRestrictionModal } from 'data/api'
import useTracking from 'hooks/useTracking'
import { clickEvent, viewEvent } from 'libs/common/event-tracker/events'
import { Screen } from 'constants/tracking/screens'
import { ViewableElement } from 'constants/tracking/viewable-elements'
import { ClickableElement } from 'constants/tracking/clickable-elements'
import useRefUrl from 'hooks/useRefUrl'

type Props = {
  show: boolean
  isSellingBlocked: boolean
  screen?: Screen
  onClose: () => void
}

const TaxpayerRestrictionInfoModal = ({ show, isSellingBlocked, screen, onClose }: Props) => {
  const translate = useTranslate('taxpayer_restriction_info_modal')
  const { track } = useTracking()
  const refUrl = useRefUrl()

  const title = isSellingBlocked
    ? translate('selling_blocked.title')
    : translate('restricted.title')
  const body = isSellingBlocked ? translate('selling_blocked.body') : translate('restricted.body')

  const currentClickableElement = isSellingBlocked
    ? ClickableElement.TaxpayersSalesBlockModal
    : ClickableElement.TaxpayersBalanceBlockModal

  useEffect(() => {
    if (!show) return

    track(
      viewEvent({
        screen,
        target: isSellingBlocked
          ? ViewableElement.TaxpayersSalesBlockModal
          : ViewableElement.TaxpayersBalanceBlockModal,
      }),
    )
  }, [track, show, isSellingBlocked, screen])

  const handleFinaliseReport = () => {
    track(
      clickEvent({
        screen,
        target: currentClickableElement,
        targetDetails: 'finalise',
      }),
    )

    dismissTaxpayerRestrictionModal()
    navigateToPage(TAXPAYER_FORM_URL_WITH_REF(refUrl))
  }

  const handleLearnMore = () => {
    track(
      clickEvent({
        screen,
        target: currentClickableElement,
        targetDetails: 'learn',
      }),
    )

    dismissTaxpayerRestrictionModal()
    navigateToPage(TAXPAYER_EDUCATION_URL_WITH_REF(refUrl))
  }

  const handleClose = () => {
    track(
      clickEvent({
        screen,
        target: currentClickableElement,
        targetDetails: 'close',
      }),
    )
    onClose()
  }

  return (
    <Dialog show={show} className="u-flexbox u-flex-direction-column">
      <Navigation
        body={<Text type={Text.Type.Title} text={translate('heading')} bold />}
        right={
          <Button
            styling={Button.Styling.Flat}
            onClick={handleClose}
            testId="taxpayer-restriction-modal-close"
            iconName={X16}
            inline
          />
        }
      />
      <Divider />
      <Cell>
        <Text type={Text.Type.Heading} text={title} />
        <Spacer size={Spacer.Size.Large} />
        <Text type={Text.Type.Body} text={body} />
        <Spacer size={Spacer.Size.XLarge} />
        <Button
          styling={Button.Styling.Filled}
          text={translate('actions.finalise')}
          onClick={handleFinaliseReport}
        />
        <Spacer size={Spacer.Size.Large} />
        <Button
          styling={Button.Styling.Outlined}
          text={translate('actions.learn_more')}
          onClick={handleLearnMore}
        />
      </Cell>
    </Dialog>
  )
}

export default TaxpayerRestrictionInfoModal
