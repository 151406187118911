'use client'

import { useEffect, useState } from 'react'
import { X24 } from '@vinted/monochrome-icons'
import { Dialog, Navigation, Text, Button, Cell, Image, Spacer } from '@vinted/web-ui'

import { Screen } from 'constants/tracking/screens'
import useAsset from 'hooks/useAsset'
import useTracking from 'hooks/useTracking'
import useTranslate from 'hooks/useTranslate'
import { getSessionStorageItem, removeSessionStorageItem } from 'libs/utils/sessionStorage'
import { clickEvent, viewEvent, viewScreenEvent } from 'libs/common/event-tracker/events'
import { ClickableElement } from 'constants/tracking/clickable-elements'

import { SPECIAL_VERIFICATION_SESSION_ID_KEY } from 'pages/TaxpayersSpecialVerificationForm/constants'
import { ViewableElement } from 'constants/tracking/viewable-elements'

const TaxpayersSpecialVerificationSuccessModal = () => {
  const translate = useTranslate('taxpayer_special_verification.success_modal')
  const { track } = useTracking()
  const asset = useAsset('/assets/taxpayers-special-verification')

  const specialVerificationSessionId = getSessionStorageItem(SPECIAL_VERIFICATION_SESSION_ID_KEY)

  const [show, setShow] = useState(!!specialVerificationSessionId)

  useEffect(() => {
    if (!show) return

    track(
      viewEvent({
        screen: Screen.TaxpayersSpecialVerificationSuccess,
        target: ViewableElement.VerificationCompleted,
        targetDetails: JSON.stringify({ verification_id: specialVerificationSessionId }),
      }),
    )
    track(viewScreenEvent({ screen: Screen.TaxpayersSpecialVerificationSuccess }))

    removeSessionStorageItem(SPECIAL_VERIFICATION_SESSION_ID_KEY)
  }, [show, specialVerificationSessionId, track])

  const handleClose = () => {
    track(
      clickEvent({
        target: ClickableElement.FinishSpecialVerification,
        screen: Screen.TaxpayersSpecialVerificationSuccess,
      }),
    )

    setShow(false)
  }

  const renderHeader = () => {
    return (
      <>
        <Navigation
          right={
            <Button
              testId="special-verification-close-button"
              styling={Button.Styling.Flat}
              onClick={handleClose}
              iconName={X24}
              inline
            />
          }
        />
        <Image src={asset('/document-clock.svg')} alt="" />
        <Spacer size={Spacer.Size.XLarge} />
      </>
    )
  }

  const renderBody = () => {
    return (
      <>
        <Text
          alignment={Text.Alignment.Center}
          type={Text.Type.Heading}
          text={translate('title')}
        />
        <Spacer size={Spacer.Size.Large} />
        <Text alignment={Text.Alignment.Center} type={Text.Type.Body} text={translate('body')} />
      </>
    )
  }

  const renderFooter = () => {
    return (
      <>
        <Spacer size={Spacer.Size.Large} />
        <Button
          styling={Button.Styling.Filled}
          text={translate('actions.finish')}
          testId="special-verification-finish-button"
          onClick={handleClose}
        />
      </>
    )
  }

  return (
    <Dialog show={show} className="u-text-center">
      {renderHeader()}
      <Cell>
        {renderBody()}
        {renderFooter()}
      </Cell>
    </Dialog>
  )
}

export default TaxpayersSpecialVerificationSuccessModal
